<template>
  <div class="first">
    <div class="tabs">
      <el-tabs v-model="activeTab" stretch>
        <el-tab-pane label="教学综合能力评测" name="first">
          <FirstPart></FirstPart>
        </el-tab-pane>
        <el-tab-pane label="网培政策规范评测" name="second">
          <SecondPart></SecondPart>
        </el-tab-pane>
        <el-tab-pane label="教学数字化能力评测" name="third">
          <ThirdPant></ThirdPant>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import FirstPart from '../views/FirstPart'
import SecondPart from '../views/SecondPart'
import ThirdPant from '../views/ThirdPart'
export default {
  name: 'Evaluationnotes',
  components: { FirstPart, SecondPart, ThirdPant },
  data() {
    return {
      activeTab: 'first',
    }
  },
}
</script>
<style lang="less" scoped>
.first {
  font-weight: normal;
  font-size: 16px;
  width: 60%;
  min-width: 1200px;
  margin: 40px auto 0;
  position: relative;
  color: #656565;
  .tabs {
    max-width: 1200px;
    margin: 0px auto;
    /deep/.el-tabs__header {
      padding: 0;
      position: relative;
      margin: 15px auto;
      max-width: 900px;
    }
    /deep/.el-tabs__item {
      font-size: 20px;
      color: #000000;
      padding-bottom: 60px;
    }
    /deep/.el-tabs__item.is-active {
      color: #960f23;
    }
    /deep/.el-tabs__active-bar {
      height: 5px;
      background-color: #960f23;
    }
  }
}
</style>
